import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { HelperProvider } from '../../providers/helper.provider';

@Injectable({
  providedIn: 'root'
})
export class HomeService
{

  endpoint: string = '';

  constructor(
      private api: ApiService,
      public helper: HelperProvider
  ) {
    this.endpoint = this.helper.getEndpointCurrentHost();
  }

  getHome(): Observable<any>
  {
    const url = this.endpoint + '&page=home&type=pages';
    return this.api.get(url);
  }

  getMenuFooter(): Observable<any>
  {
    const url = this.endpoint + '&type=menu-footer';
    return this.api.get(url);
  }


  getHeader(isoCode: any = null): Observable<any>
  {
    let url = this.endpoint + '&type=menu-top';
    if (isoCode) {
      url = this.helper.getDynamicStringEndpoint(isoCode) + '&type=menu-top';
    }

    return this.api.get(url);
  }


  getMegaMenuProducts(isoCode: any = null)
  {
    let url = this.endpoint + '&page=mega-menu-products&type=pages';
    if (isoCode) {
      url = this.helper.getDynamicStringEndpoint(isoCode) + '&page=mega-menu-products&type=pages';
    }
    return this.api.get(url);
  }

  getLogo(isoCode: any = null)
  {
    let url = this.endpoint + '&page=logo&type=pages';
    if (isoCode) {
      url = this.helper.getDynamicStringEndpoint(isoCode) + '&page=logo&type=pages';
    }
    return this.api.get(url);
  }

  getLegal(): Observable<any>
  {
    const url = this.endpoint + '&page=legal-footer&type=pages';
    return this.api.get(url);
  }

}
