import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content05',
  templateUrl: './content05.component.html',
  styleUrls: ['./content05.component.scss']
})
export class Content05Component implements OnInit
{

  @Input() data;

  constructor() { }

  ngOnInit() {}

}
