import { Component, OnInit } from '@angular/core';
import { AboutService } from '../../services/about/about.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit
{

    public data;

    constructor(
        private aboutService: AboutService,
        private meta: Meta,
        private titleService: Title,
        public helper: HelperProvider,
        public translate: TranslateService
    ) {
    }

    ngOnInit()
    {
        this.getAboutUs();
    }


    getAboutUs()
    {
        this.aboutService.getAboutMainPage().subscribe((about: any) => {
            this.data = (about.length > 0) ? about[0].acf : about.acf;

            if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
                this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
                this.titleService.setTitle(this.data.meta_tag_title.content);
            }

            if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
                this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
                this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
            }
        });
    }
}
