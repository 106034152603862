import { Component, OnInit } from '@angular/core';
import { AboutService } from '../../services/about/about.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';

@Component({
  selector: 'app-about-us02',
  templateUrl: './about-us02.component.html',
  styleUrls: ['./about-us02.component.scss']
})
export class AboutUs02Component implements OnInit
{

  public data;

  constructor(
      private aboutService: AboutService,
      private meta: Meta,
      private titleService: Title,
      public helper: HelperProvider
  ) {
  }

  ngOnInit()
  {
    this.getAbout();
  }


  getAbout()
  {
    /*
    this.aboutService.getAbout(this.helper.getCurrentLang(), 2).subscribe((about: any) => {
      if (about.length > 0) {
        this.data = about[0].acf;
        // SEO Tags
        this.titleService.setTitle(this.data.meta_tag_title.content);
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
    });

     */
  }
}
