import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content02',
  templateUrl: './content02.component.html',
  styleUrls: ['./content02.component.scss']
})
export class Content02Component implements OnInit
{

  @Input() data;

  constructor() { }

  ngOnInit() {}

}
