import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';
import { OurMissionVisionService } from '../../services/our-mission-vision/our-mission-vision.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-our-mission-vision',
  templateUrl: './our-mission-vision.component.html',
  styleUrls: ['./our-mission-vision.component.scss']
})
export class OurMissionVisionComponent implements OnInit
{

  public data;

  constructor(
      public service: OurMissionVisionService,
      private meta: Meta,
      private titleService: Title,
      public helper: HelperProvider,
      public translate: TranslateService
  ) {
  }

  ngOnInit()
  {
    this.getOurMissionVision();
  }

  getOurMissionVision()
  {
    this.service.getOurMissionVision().subscribe((mission: any) => {
      this.data = (mission.length > 0) ? mission[0].acf : mission.acf;
      if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
        this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
        this.titleService.setTitle(this.data.meta_tag_title.content);
      }

      if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
        this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
    });
  }

}
