import { Component, Input, OnInit } from '@angular/core';
import { HelperProvider } from '../../providers/helper.provider';

@Component({
  selector: 'app-content-jobs-link',
  templateUrl: './content-jobs-link.component.html',
  styleUrls: ['./content-jobs-link.component.scss']
})
export class ContentJobsLinkComponent implements OnInit
{

  @Input() data;

  constructor(
      public heloer: HelperProvider
  ) { }

  ngOnInit() {}
}
