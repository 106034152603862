import { Component, OnInit } from '@angular/core';
import { NewsService } from '../../services/news/news.service';
import { ActivatedRoute } from '@angular/router';
import { HelperProvider } from '../../providers/helper.provider';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-news02',
  templateUrl: './news02.component.html',
  styleUrls: ['./news02.component.scss']
})
export class News02Component implements OnInit
{

  public data;
  public slug;
  private id;

  constructor(
      private newsService: NewsService,
      private route: ActivatedRoute,
      public helper: HelperProvider,
      public translate: TranslateService
  ) {}

  ngOnInit()
  {
    this.route.params.subscribe((params: any) => {
      this.slug = params['id'];
      this.id = parseInt(this.slug.replace('news', ''), 10);
      this.getNew();
    });
  }

  getNew()
  {
    this.newsService.getNew('allnews').subscribe((news: any) => {
      const n = (news.length > 0) ? JSON.parse(news[0].acf.all_news) : JSON.parse(news.acf.all_news);
      this.data = n.reverse();
    });
  }

}
