import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-block-cta',
  templateUrl: './block-cta.component.html',
  styleUrls: ['./block-cta.component.scss']
})
export class BlockCtaComponent implements OnInit
{

  @Input() data;

  constructor(
      public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.data && this.data.hasOwnProperty('text')) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = this.data.text;

      const listItems = tempDiv.querySelectorAll('li');
      listItems.forEach(li => {
        const span = li.querySelector('span');
        if (span) {
          const color = span.style.color;
          if (color) {
            li.style.color = color;
          }
        }
      });

      this.data.text = tempDiv.innerHTML;
    }
  }

}
