import { Component, Input, OnInit } from '@angular/core';
import { HelperProvider } from '../../providers/helper.provider';

@Component({
  selector: 'app-content04',
  templateUrl: './content04.component.html',
  styleUrls: ['./content04.component.scss']
})
export class Content04Component implements OnInit
{

  @Input() data;

  constructor(
      public helper: HelperProvider
  ) { }

  ngOnInit() {}
}
