import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HelperProvider } from '../../providers/helper.provider';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss']
})
export class RelatedProductsComponent implements OnInit, OnChanges
{

  @Input() slug;
  @Input() data;
  productList;

  constructor(
      public helper: HelperProvider
  ) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges)
  {
    if (changes.data && this.data.products_title_list) {
      const titleList = JSON.parse(this.data.products_title_list);
      this.productList = titleList.filter(_ => _.SLUG !== this.slug);
      this.productList = this.productList.filter((item, index) => index < 2);
    }
  }
}
