import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HelperProvider } from '../../providers/helper.provider';
import { GapcalculatorService } from '../../services/gapcalculator/gapcalculator.service';

@Component({
  selector: 'app-gapcalculator',
  templateUrl: './gapcalculator.component.html',
  styleUrls: ['./gapcalculator.component.scss']
})
export class GapcalculatorComponent implements OnInit, OnChanges
{

  @Input() data;

  calculator: any = {
    gross_amount: null,
    net_amount: null,
    children: null,
    state: null
  };

  dataCalculator: any;
  disabledForm: boolean = true;
  ttdGap: number = 0;
  iuGap: number = 0;
  sickPayBenefit: number = 0;
  unemploymentBenefit: number = 0;
  texto: string = 'Calcula tu brecha de ingresos';

  constructor(
      public helper: HelperProvider,
      public gapcalculatorService: GapcalculatorService
  ) { }

  ngOnInit()
  {
    this.getCalculatorData();
  }

  ngOnChanges(changes: SimpleChanges)
  {
    if (changes.data) {
      this.data = changes.data.currentValue;
    }
  }

  getCalculatorData()
  {
    this.gapcalculatorService.getGapCalculator().subscribe((response: any) => {
      this.dataCalculator = (response.length > 0) ? response[0].acf : response.acf;
    });
  }

  checkEnabledForm(event: any)
  {
    this.disabledForm = !(this.calculator.gross_amount > 0 && this.calculator.net_amount > 0 && this.calculator.children !== null && this.calculator.state !== null);

    if (!this.disabledForm) {
      this.calculate();
      const btn = document.getElementById('btnSubmit');
      if (btn) {
        setTimeout(() => {
          btn.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 600);
      }
    }
  }


  calculate()
  {
    if (!this.dataCalculator) {
      return false;
    }

    const children = {
      quantity: 0,
      percentage: 0,
      net_salary: 0
    };
    let stateQuantity: any = 0;
    let thresholdBreachedIU: any = 0;
    let lowestAmountIncome: any = 0;

    const percentageOfTheIncomeThreshold = parseFloat(this.dataCalculator.ttd_calculations.percentage_of_the_income_threshold);
    const percentOfGrossIncome = parseFloat(this.dataCalculator.ttd_calculations.percent_of_gross_income);
    const percentOfNetIncome = parseFloat(this.dataCalculator.ttd_calculations.percent_of_net_income);
    const quantityIncomeThreshold = (parseFloat(this.dataCalculator.ttd_calculations.quantity_income_threshold) * percentageOfTheIncomeThreshold) / 100;
    const grossIncome = (this.calculator.gross_amount * percentOfGrossIncome) / 100;
    const netIncomeWithPercentage = (this.calculator.net_amount * percentOfNetIncome) / 100;
    const netIncome = this.calculator.net_amount;

    if (quantityIncomeThreshold < grossIncome) {
      lowestAmountIncome = quantityIncomeThreshold;
    } else {
      lowestAmountIncome = grossIncome;
    }

    if (netIncomeWithPercentage < lowestAmountIncome) {
      lowestAmountIncome = netIncomeWithPercentage;
    }

    const percentageDeduction = parseFloat(this.dataCalculator.ttd_calculations.percentage_deduction);
    this.sickPayBenefit = Math.round(lowestAmountIncome - ((lowestAmountIncome * percentageDeduction) / 100));
    this.ttdGap = Math.round(netIncome - this.sickPayBenefit);

    if (this.calculator.children) {
      children.quantity = this.dataCalculator.iu_calculations.yes_children.quantity;
      children.percentage = this.dataCalculator.iu_calculations.yes_children.percentage;
      stateQuantity = this.dataCalculator.iu_calculations.yes_children.state;
    } else {
      children.quantity = this.dataCalculator.iu_calculations.no_children.quantity;
      children.percentage = this.dataCalculator.iu_calculations.no_children.percentage;
      stateQuantity = this.dataCalculator.iu_calculations.no_children.state;
    }

    children.net_salary = (this.calculator.net_amount * children.percentage) / 100;

    if (this.calculator.state) {
      stateQuantity = stateQuantity.quantity_old;
    } else {
      stateQuantity = stateQuantity.quantity_new;
    }

    thresholdBreachedIU = (stateQuantity > children.net_salary) ? children.net_salary : stateQuantity;

    this.iuGap = Math.ceil(this.calculator.net_amount - thresholdBreachedIU);
    this.unemploymentBenefit = Math.round(thresholdBreachedIU);
  }

}
