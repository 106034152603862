// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.articleSection {
  margin-bottom: 140px;
}
.articleSection.last {
  margin-bottom: 50px;
}
.articleSection .mainTitle {
  font: 36px/40px "opensans", Arial, Helvetica, Sans-Serif;
  font-weight: 600;
  color: #1c1d1e;
  margin-top: 90px;
  margin-bottom: 50px;
}
.articleSection .mainText {
  font: 14px/22px "opensans", Arial, Helvetica, Sans-Serif;
  font-weight: 400;
  color: #37393e;
  margin-bottom: 30px;
}
.articleSection .mainText .markText {
  font: 14px/22px "opensans", Arial, Helvetica, Sans-Serif;
  font-weight: 600;
}
.articleSection a {
  font: 16px/28px "opensans", Arial, Helvetica, Sans-Serif;
  font-weight: 700;
  border-bottom: 2px solid #e7e7e7;
  color: #ed2d26;
}

img {
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/articles-two-sides/articles-two-sides.component.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;AAAI;EACI,mBAAA;AAER;AAAI;EACI,wDAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AAER;AAAI;EACI,wDAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AAER;AADQ;EACI,wDAAA;EACA,gBAAA;AAGZ;AAAI;EACI,wDAAA;EACA,gBAAA;EACA,gCAAA;EACA,cAAA;AAER;;AACA;EACI,eAAA;AAEJ","sourcesContent":[".articleSection{\r\n    margin-bottom: 140px;\r\n    &.last{\r\n        margin-bottom: 50px;\r\n    }\r\n    .mainTitle{\r\n        font: 36px/40px \"opensans\", Arial, Helvetica, Sans-Serif;\r\n        font-weight: 600;\r\n        color: #1c1d1e;\r\n        margin-top: 90px;\r\n        margin-bottom: 50px;\r\n    }\r\n    .mainText{\r\n        font: 14px/22px \"opensans\", Arial, Helvetica, Sans-Serif;\r\n        font-weight: 400;\r\n        color: #37393e;\r\n        margin-bottom: 30px;\r\n        .markText{\r\n            font: 14px/22px \"opensans\", Arial, Helvetica, Sans-Serif;\r\n            font-weight: 600;\r\n        }\r\n    }\r\n    a{\r\n        font: 16px/28px \"opensans\", Arial, Helvetica, Sans-Serif;\r\n        font-weight: 700;\r\n        border-bottom: 2px solid #e7e7e7;\r\n        color: #ed2d26;\r\n    }\r\n}\r\nimg{\r\n    max-width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
