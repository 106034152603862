import { Component, OnInit } from '@angular/core';
import { CareersService } from '../../services/careers/careers.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-careers05',
  templateUrl: './careers05.component.html',
  styleUrls: ['./careers05.component.scss']
})
export class Careers05Component implements OnInit
{

  public data;

  constructor(
      private careersService: CareersService,
      private meta: Meta,
      private titleService: Title,
      public helper: HelperProvider,
      public translate: TranslateService
  ) { }

  ngOnInit()
  {
    this.getCareer();
  }

  getCareer()
  {
    this.careersService.getCareer( '05').subscribe((careers: any) => {
      this.data = (careers.length > 0) ? careers[0].acf : careers.acf;
      // SEO Tags
      if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
        this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
        this.titleService.setTitle(this.data.meta_tag_title.content);
      }

      if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
        this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
    });
  }
}
