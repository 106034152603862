import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { HelperProvider } from '../../providers/helper.provider';

@Injectable({
  providedIn: 'root'
})
export class CovidService
{

  endpoint: string = '';

  constructor(
      private api: ApiService,
      public helper: HelperProvider
  ) {
    this.endpoint = this.helper.getEndpointCurrentHost();
  }

  getItems(page = ''): Observable<any>
  {
    const url = this.endpoint + '&page=' + page + '&type=covid19';
    return this.api.get(url);
  }

}
