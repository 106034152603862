import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.scss']
})
export class BenefitComponent implements OnInit
{

  @Input() benefit;

  constructor() { }

  ngOnInit() {}

}
