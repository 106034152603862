import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { Observable } from 'rxjs';
import { HelperProvider } from '../providers/helper.provider';

@Injectable({
    providedIn: 'root'
})
export class LanguagesService
{
    endpoint: string = '';

    constructor(
        private api: ApiService,
        public helper: HelperProvider
    ) {
        this.endpoint = this.helper.getEndpointCurrentHost(false);
    }

    getLanguages(): Observable<any>
    {
        const url = this.endpoint + '&page=list-languages&type=pages';
        return this.api.get(url);
    }
}
