import {Component, OnInit} from '@angular/core';
import { ProductService } from '../../services/product/product.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-products-list',
    templateUrl: './products-list.component.html',
    styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit
{

    public data;

    constructor(
        private productsService: ProductService,
        private meta: Meta,
        private titleService: Title,
        public helper: HelperProvider,
        public translate: TranslateService
    ) {}

    ngOnInit()
    {
        this.getProducts();
    }

    getProducts()
    {
        this.productsService.getProductsPage().subscribe((products: any) => {
            this.data = (products.length > 0) ? products[0].acf : products.acf;
            // SEO Tags
            if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
                this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
                this.titleService.setTitle(this.data.meta_tag_title.content);
            }

            if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
                this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
                this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
            }
        });
    }

    scrollTo(id)
    {
        const el = document.getElementById(id);
        let actualScroll = window.pageYOffset;

        if (el) {
            const scrollInterval = setInterval(() => {
                if ( actualScroll >= el.offsetTop - 120 ) {
                    clearInterval(scrollInterval);
                } else {
                    actualScroll += 30;
                    window.scrollTo(0, actualScroll);
                }
            }, 10);
        }
    }
}
