import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { HelperProvider } from '../../providers/helper.provider';
import { DomSanitizer } from '@angular/platform-browser';

declare var dataLayer;

@Component({
  selector: 'app-block-video',
  templateUrl: './block-video.component.html',
  styleUrls: ['./block-video.component.scss']
})
export class BlockVideoComponent implements OnInit, OnChanges, OnDestroy {

  @Input() data;

  vimeoUrlVideo: any;
  play: boolean = false;

  constructor(
      public helper: HelperProvider,
      public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {}

  ngOnChanges(changes: any)
  {
    if (this.data) {
      this.vimeo();
    }
  }

  ngOnDestroy()
  {
    dataLayer.push({
      event: 'video_player_dispose',
      videoUrl: this.vimeoUrlVideo
    });
  }

  vimeo()
  {
    const vimeoId = this.helper.getVimeoIdFromUrl(this.data.content.url_video || this.data.content.iframe_video);
    if (this.data.show_block_video && vimeoId) {
      this.vimeoUrlVideo = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + vimeoId + '?dnt=1');
      setTimeout(() => {
        const player = this.helper.loadVimeo('vimeo', vimeoId);
        if (player) {
          player.on('play', () => {
            if (!this.play) {
              this.play = true;
              dataLayer.push({
                event: 'video_player_firstplay',
                videoUrl: this.vimeoUrlVideo
              });
            } else {
              dataLayer.push({
                event: 'video_player_play',
                videoUrl: this.vimeoUrlVideo
              });
            }
          });

          player.on('pause', () => {
            dataLayer.push({
              event: 'video_player_pause',
              videoUrl: this.vimeoUrlVideo
            });
          });

          player.on('ended', () => {
            dataLayer.push({
              event: 'video_player_ended',
              videoUrl: this.vimeoUrlVideo
            });
          });
        }
      }, 1000);
    }
  }

}
