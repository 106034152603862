import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AboutUsComponent } from "./views/about-us/about-us.component";
import { AboutUs02Component } from "./views/about-us02/about-us02.component";
import { AboutUs03Component } from "./views/about-us03/about-us03.component";
import { AboutUs04Component } from "./views/about-us04/about-us04.component";
import { ProductsListComponent } from "./views/products-list/products-list.component";
import { ProductComponent } from "./views/product/product.component";
import { HomeComponent } from "./views/home/home.component";
import { ReportsComponent } from "./views/reports/reports.component";
import { ContactComponent } from "./views/contact/contact.component";
import { Careers01Component } from "./views/careers01/careers01.component";
import { Careers02Component } from "./views/careers02/careers02.component";
import { Careers03Component } from "./views/careers03/careers03.component";
import { Careers04Component } from "./views/careers04/careers04.component";
import { Careers05Component } from "./views/careers05/careers05.component";
import { LegalComponent } from "./views/legal/legal.component";
import { News01Component } from "./views/news01/news01.component";
import { News02Component } from "./views/news02/news02.component";
import { CovidPostComponent } from "./views/covidPost/covidPost.component";
import { MakeAClaimComponent } from "./views/makeAClaim/makeAClaim.component";
import { ProductCreditProtectionComponent } from "./views/productCreditProtection/productCreditProtection.component";
import { ProductLifeProtectionComponent } from "./views/productLifeProtection/productLifeProtection.component";
import { TermsComponent } from "./views/terms/terms.component";
import { PrivacyComponent } from "./views/privacy/privacy.component";
import { JobDetailComponent } from "./components/job-detail/job-detail.component";
import { PrivacyPoliciesComponent } from "./views/privacy-policies/privacy-policies.component";
import { PageNotFoundComponent } from "./views/page-not-found/page-not-found.component";
import { ProductPossesionProtectionComponent } from "./views/productPossesionProtection/productPossesionProtection.component";
import { OurMissionVisionComponent } from "./views/our-mission-vision/our-mission-vision.component";
import { PageComponent } from "./views/page/page.component";
import { SimpleProductComponent } from "./views/simple-product/simple-product.component";
import { GapcalculatorComponent } from "./components/gapcalculator-component/gapcalculator.component";

const routes: Routes = [
  // SPAIN
  { path: "sobre-nosotros", component: AboutUsComponent },
  // { path: "gap", component: GapcalculatorComponent },
  { path: "productos", component: ProductsListComponent },
  { path: "nuestra-mision-y-vision", component: OurMissionVisionComponent },
  { path: "mision-y-vision", component: OurMissionVisionComponent },
  { path: "declarar-un-siniestro", component: MakeAClaimComponent },
  { path: "contacto", component: ContactComponent },
  { path: "informacion-legal-y-regulatoria", component: LegalComponent },
  { path: "politica-de-privacidad", component: PrivacyComponent },
  { path: "terminos-y-condiciones", component: TermsComponent },
  { path: "quejas-y-reclamaciones", component: ReportsComponent },
  { path: "categoria/:id", component: ProductComponent },

  // PORTUGAL
  { path: "sobre-nos", component: AboutUsComponent },
  { path: "produtos", component: ProductsListComponent },
  { path: "nossa-missao-visao", component: OurMissionVisionComponent },
  { path: "participar-um-sinistro", component: MakeAClaimComponent },
  { path: "contacto", component: ContactComponent },
  { path: "legal", component: LegalComponent },
  { path: "politica-de-privacidade", component: PrivacyComponent },
  { path: "termos-e-condicoes", component: TermsComponent },
  { path: "reclamacoes-e-reclamacoes", component: ReportsComponent },
  { path: "category/:id", component: ProductComponent },
  { path: "produtos/:id", component: SimpleProductComponent },

  // GERMANY
  { path: "uber-uns", component: AboutUsComponent },
  { path: "unsere-mission-vision", component: OurMissionVisionComponent },
  { path: "unsere-mission-und-vision", component: OurMissionVisionComponent },
  { path: "einen-leistungsfall-melden", component: MakeAClaimComponent },
  { path: "kontakt", component: ContactComponent },
  { path: "rechtshinweise", component: LegalComponent },
  { path: "datenschutzerklarung", component: PrivacyComponent },
  { path: "nutzungsbedingungen", component: TermsComponent },
  { path: "beschwerden-und-anspruche", component: ReportsComponent },
  { path: "produkte", component: ProductsListComponent },
  { path: "produkte/:id", component: SimpleProductComponent },
  { path: "kategorie/:id", component: ProductComponent },
  { path: "impressum", component: PageComponent, data: { id: "impressum" } },
  {
    path: "europassistance",
    component: PageComponent,
    data: { id: "europassistance" },
  },
  {
    path: "LeasingAktion",
    component: SimpleProductComponent,
    data: { id: "leasingAktion" },
  },

  // AUSTRIA
  { path: "uber-uns", component: AboutUsComponent },
  { path: "unsere-mission-vision", component: OurMissionVisionComponent },
  { path: "versicherungsfall-melden", component: MakeAClaimComponent },
  { path: "kontakt", component: ContactComponent },
  { path: "rechtshinweise", component: LegalComponent },
  { path: "datenschutzerklarung", component: PrivacyComponent },
  { path: "nutzungsbedingungen", component: TermsComponent },
  { path: "beschwerden-und-anspruche", component: ReportsComponent },
  { path: "produkte", component: ProductsListComponent },
  { path: "produkte/:id", component: SimpleProductComponent },
  { path: "kategorie/:id", component: ProductComponent },
  { path: "impressum", component: PageComponent, data: { id: "impressum" } },

  // NETHERLANDS
  { path: "over-ons", component: AboutUsComponent },
  { path: "onze-missie-en-visie", component: OurMissionVisionComponent },
  { path: "een-claim-indienen", component: MakeAClaimComponent },
  { path: "contact", component: ContactComponent },

  // Poland
  { path: "o-nas", component: AboutUsComponent },
  { path: "nasza-misja-i-wizja", component: OurMissionVisionComponent },
  { path: "zglos-roszczenie", component: MakeAClaimComponent },
  { path: "kontakt", component: ContactComponent },
  { path: "prawny", component: LegalComponent },
  { path: "polityka-prywatnosci", component: PrivacyComponent },
  { path: "regulamin", component: TermsComponent },
  { path: "reklamacje-i-reklamacje", component: ReportsComponent },
  { path: "kategoria/:id", component: ProductComponent },
  { path: "produkty", component: ProductsListComponent },
  { path: "produkty/:id", component: SimpleProductComponent },

  // Italy
  { path: "chi-siamo", component: AboutUsComponent },
  { path: "la-nostra-mission-e-vision", component: OurMissionVisionComponent },
  { path: "denuncia-un-sinistro", component: MakeAClaimComponent },
  { path: "contattaci", component: ContactComponent },
  { path: "legale", component: LegalComponent },
  { path: "politica-sulla-riservatezza", component: PrivacyComponent },
  { path: "termini-e-condizioni", component: TermsComponent },
  { path: "reclami-e-reclami", component: ReportsComponent },
  { path: "prodotti", component: ProductsListComponent },
  { path: "prodotti/:id", component: SimpleProductComponent },

  // Belgium Flemish
  { path: "over-ons", component: AboutUsComponent },
  { path: "onze-missie-en-visie", component: OurMissionVisionComponent },
  { path: "producten", component: ProductsListComponent },
  { path: "schadegeval-indienen", component: MakeAClaimComponent },
  { path: "categorie/:id", component: ProductComponent },

  // Belgium French
  { path: "a-propos", component: AboutUsComponent },
  { path: "notre-mission-vision", component: OurMissionVisionComponent },
  { path: "produits", component: ProductsListComponent },
  { path: "declarer-sinistre", component: MakeAClaimComponent },
  { path: "contacter", component: ContactComponent },
  { path: "categorie/:id", component: ProductComponent },

  // Finland
  { path: "tietoa-meista", component: AboutUsComponent },
  { path: "tehtavamme-ja-nakemyksemme", component: OurMissionVisionComponent },
  { path: "tuotteet", component: ProductsListComponent },
  { path: "tee-korvaushakemus", component: MakeAClaimComponent },
  { path: "yhteystiedot", component: ContactComponent },
  { path: "kategoria/:id", component: ProductComponent },

  // Sweden
  { path: "om-oss", component: AboutUsComponent },
  { path: "mission-och-vision", component: OurMissionVisionComponent },
  { path: "produkter", component: ProductsListComponent },
  { path: "gora-en-skadeanmalan", component: MakeAClaimComponent },
  { path: "kategori/:id", component: ProductComponent },

  // DENMARK
  { path: "om-os", component: AboutUsComponent },
  { path: "vores-mission-vision", component: OurMissionVisionComponent },
  { path: "produkter", component: ProductsListComponent },
  { path: "opret-en-skadeanmeldelse", component: MakeAClaimComponent },

  // NORWAY
  { path: "om-oss", component: AboutUsComponent },
  { path: "vart-formal-og-var-visjon", component: OurMissionVisionComponent },
  { path: "produkter", component: ProductsListComponent },
  { path: "fremme-et-krav", component: MakeAClaimComponent },
  { path: "kontakt", component: ContactComponent },

  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "p/:id", component: PageComponent },
  { path: "aboutus", component: AboutUsComponent },
  { path: "missionvision", component: AboutUs02Component },
  { path: "ourvalues", component: AboutUs03Component },
  { path: "corporateculture", component: AboutUs04Component },
  { path: "products", component: ProductsListComponent },
  {
    path: "products/life-protection",
    component: ProductLifeProtectionComponent,
  },
  {
    path: "products/possesion-protection",
    component: ProductPossesionProtectionComponent,
  },
  {
    path: "products/credit-protection",
    component: ProductCreditProtectionComponent,
  },
  { path: "products/:id", component: SimpleProductComponent },
  { path: "category/:id", component: ProductComponent },
  { path: "careershome", component: Careers01Component },
  { path: "careers/webelieveinyou", component: Careers02Component },
  { path: "jobs", component: Careers03Component },
  { path: "careers/workwithus", component: Careers04Component },
  { path: "careers/greatplacetowork", component: Careers05Component },
  { path: "careers/job-detail", component: JobDetailComponent },
  { path: "news", component: News01Component },
  { path: "corporate-news/:id", component: News02Component },
  { path: "covid19/:id", component: CovidPostComponent },
  { path: "reports", component: ReportsComponent },
  { path: "make-a-claim", component: MakeAClaimComponent },
  {
    path: "product-credit-protection",
    component: ProductCreditProtectionComponent,
  },
  { path: "contact", component: ContactComponent },
  { path: "legal", component: LegalComponent },
  { path: "terms-and-conditions", component: TermsComponent },
  { path: "privacy-policy", component: PrivacyComponent },
  { path: "privacy-policies", component: PrivacyPoliciesComponent },
  { path: "our-mission-vision", component: OurMissionVisionComponent },

  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
