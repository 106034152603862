import { Component, Input, OnInit } from '@angular/core';
import { HelperProvider } from '../../providers/helper.provider';

@Component({
  selector: 'app-great-place-to-work',
  templateUrl: './great-place-to-work.component.html',
  styleUrls: ['./great-place-to-work.component.scss']
})
export class GreatPlaceToWorkComponent implements OnInit
{

  @Input() data;

  constructor(
      public helper: HelperProvider
  ) { }

  ngOnInit() {}

}
