import { Component, OnInit } from '@angular/core';
import { NewsService } from '../../services/news/news.service';
import { HelperProvider } from '../../providers/helper.provider';
import {TranslateService} from '@ngx-translate/core';

@Component({
  templateUrl: './news01.component.html',
  styleUrls: ['./news01.component.scss']
})
export class News01Component implements OnInit
{

  public data;
  public newsList;
  public allNews;
  public hideShowMoreLink = false;

  constructor(
      private newsService: NewsService,
      public helper: HelperProvider,
      public translate: TranslateService
  ) {}

  ngOnInit()
  {
   this.getNews();
  }

  showAll()
  {
    this.newsList = this.allNews;
    this.hideShowMoreLink = true;
  }

  getNews()
  {
    this.newsService.getNews().subscribe((news: any) => {
      this.data = (news.length > 0) ? news[0].acf : news.acf;
      this.allNews = JSON.parse(this.data.news_list);
      this.newsList = this.allNews.slice(0, 4);
      if (this.allNews.length <= 4) {
        this.hideShowMoreLink = true;
      }
    });
  }

}
