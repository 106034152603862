import { Component, OnInit, Input } from '@angular/core';
import { HelperProvider } from '../../providers/helper.provider';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  @Input() data: any;

  constructor(
      public helper: HelperProvider
  ) { }

  ngOnInit()
  {
  }


  accordionItem($event)
  {
    const title = ( $event.target.classList.contains('faq__accordion__item__title') ) ? $event.target : $event.target.parentNode;
    const item = title.parentNode;
    if (item.classList.contains('open')) {
      item.classList.remove('open');
    } else {
      item.classList.add('open');
    }
  }
}
