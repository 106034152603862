import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { HelperProvider } from '../../providers/helper.provider';

@Injectable({
  providedIn: 'root'
})
export class LegalService
{

  endpoint: string = '';

  constructor(
      private api: ApiService,
      public helper: HelperProvider
  ) {
    this.endpoint = this.helper.getEndpointCurrentHost();
  }

  getLegal(): Observable<any>
  {
    const url = this.endpoint + '&page=legal&type=pages';
    return this.api.get(url);
  }

  getPrivacy(): Observable<any>
  {
    const url = this.endpoint + '&page=privacy&type=pages';
    return this.api.get(url);
  }

  getPrivacyPolicies(): Observable<any>
  {
    const url = this.endpoint + '&page=privacy-policies&type=pages';
    return this.api.get(url);
  }

  getTerms(): Observable<any>
  {
    const url = this.endpoint + '&page=terms-and-conditions&type=pages';
    return this.api.get(url);
  }
}
