import { Component, OnInit } from '@angular/core';
import { CovidService } from '../../services/covid/covid.service';
import { ContactService } from '../../services/contact/contact.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-product',
    templateUrl: './covidPost.component.html',
    styleUrls: ['./covidPost.component.scss'],
styles: [`
   .d-none {
      display: block!important;
   }
`],
})
export class CovidPostComponent implements OnInit 
{

    public data;
    public id;
    public headerImg;
    public title;
    public subTitle;
    public buttonText;
    public content;
    public country;
    public target;
    public clickedMarker;
    public openProductsInfo;
    public selectedFileDownload: any;
    public pdfDownload;

    constructor(
        private contactService: ContactService,
        private covidService: CovidService,
        private route: ActivatedRoute,
        private router: Router,
        private meta: Meta,
        private titleService: Title,
        public helper: HelperProvider,
        public translate: TranslateService
    ) {}
    
    onNavigate(event: any, target: string = '_self')
    {
        this.router.navigate([this.helper.getRouteLink(event.target.value)]);
    }

    ngOnInit()
    {
        this.route.params.subscribe((params: any) => {
                this.id = params['id'];
                // Pedimos la lista de paises
                this.contactService.getContactDATA().subscribe((contact: any) => {
                    const contactData = (contact.length > 0) ? contact[0].acf : contact.acf;

                    // SEO Tags
                    if (contactData && contactData.hasOwnProperty('meta_tag_title')) {
                        contactData.meta_tag_title.content = contactData.meta_tag_title.content.replace(/<[^>]*>/g, '');
                        this.titleService.setTitle(contactData.meta_tag_title.content);
                    }

                    if (contactData && contactData.hasOwnProperty('meta_tag_description')) {
                        contactData.meta_tag_description.content = contactData.meta_tag_description.content.replace(/<[^>]*>/g, '');
                        this.meta.updateTag({ name: 'description', content: contactData.meta_tag_description.content });
                    }

                    this.data = {
                        country_name: contactData.country_name,
                        products: contactData.products
                    };

                    // Adaptamos la lista de nombres de la url con su nombre completo para que se llame = que en el servicio de Countries
                    switch (params['id']) {
                        case 'no': this.country = 'Norway'; break;
                        case 'be': this.country = 'Belgium'; break;
                        case 'nl': this.country = 'Netherlands'; break;
                        case 'es': this.country = 'Spain'; break;
                        case 'it': this.country = 'Italy'; break;
                        case 'at': this.country = 'Austria'; break;
                        case 'se': this.country = 'Sweden'; break;
                        case 'fi': this.country = 'Finland'; break;
                        case 'dk': this.country = 'Denmark'; break;
                        case 'de': this.country = 'Germany'; break;
                        case 'pl': this.country = 'Poland'; break;
                        case 'pt': this.country = 'Portugal'; break;
                        default: this.country = 'none'; break;
                    }

                    this.route.queryParams
                        .subscribe((p: any) => {
                            if ( Object.keys(p).length > 0 ) {
                                setTimeout(() => {
                                    this.clickedMarker(null, p);
                                    this.openProductsInfo(p);
                                }, 1000);
                            }
                        });
                });

                // Contenido de articulo
                this.covidService.getItems(this.id).subscribe((items: any) => {
                    const itemsData = (items.length > 0) ? items[0].acf : items.acf;
                    this.headerImg = itemsData.header.img;
                    this.title = itemsData.content.post_title;
                    this.subTitle = itemsData.content.post_subtitle;
                    this.content = itemsData.content.content;
                    this.buttonText = itemsData.content.button_text;
                    this.pdfDownload = itemsData.pdf_download;
                });
            }
        );
    }

    closedWindowHandler(event: any)
    {
        // console.log(event);
    }

    openList($event)
    {
        const button = ( $event.target.tagName == 'BUTTON' ) ? $event.target : $event.target.parentNode;
        const list = button.nextSibling;
        if ( list.classList.contains('open') ) {
            list.classList.remove('open');
        } else {
            list.classList.add('open');
        }
    }

    selectOption($event, product: any, variableSelected: string)
    {
        const button = ( $event.target.tagName === 'BUTTON' ) ? $event.target : $event.target.parentNode;
        const list = button.parentNode.parentNode;
        const inputGroup = list.parentNode;
        const buttonSelected = inputGroup.children[0];
        buttonSelected.children[0].innerHTML = button.innerHTML;
        list.classList.remove('open');

        this[variableSelected] = product;
    }

    downloadFile(variableSelected: string)
    {
        if (this[variableSelected]) {
            window.open(this[variableSelected].file, '_blank');
        }
    }

}
