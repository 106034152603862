// import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
// import { ApiService } from '../api/api.service';
// import { HelperProvider } from '../../providers/helper.provider';

// @Injectable({
//   providedIn: 'root'
// })
// export class ContactService
// {

//   constructor(private api: ApiService) {}

//   getContact(): Observable<any>
//   {
//     return this.api.get('md.php?page=contact&type=pages');
//   }
// }


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { HelperProvider } from '../../providers/helper.provider';

@Injectable({
  providedIn: 'root'
})
export class ContactService
{

  endpoint: string = '';
  endpointOriginal: string = '';

  constructor(
      private api: ApiService,
      public helper: HelperProvider
  ) {
    this.endpoint = this.helper.getEndpointCurrentHost();
    this.endpointOriginal = this.helper.getEndpoint('cnpsantander.com');
  }

  getContactDATA(): Observable<any>
  {
    const url = this.endpoint + '&page=new-contact&type=pages';
    console.log("URL:", url);
    return this.api.get(url);
  }

    getContactMAP(): Observable<any>
  {
    return this.api.get('md.php?page=contact&type=pages');
  }


  getContactOriginal(): Observable<any>
  {
    const url = this.endpointOriginal + '&page=contact&type=pages';
    return this.api.get(url);
  }
}
