import {Component, ElementRef, Input, OnChanges, OnInit, Output} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-covid-contact',
  templateUrl: './covid-contact.component.html',
  styleUrls: ['./covid-contact.component.scss']
})
export class CovidContactComponent implements OnInit, OnChanges
{

  @Input() data;
  products;

  @Output() infoClosed = new EventEmitter<boolean>();

  constructor(private elRef: ElementRef) { }

  ngOnInit() {}

  ngOnChanges(changes: any)
  {
      if (this.data) {
        const obj = this.data.products;
        this.products = Object.keys(obj).map(e => obj[e]);
      }
  }

  closeInfo(country)
  {
    const infoContent = this.elRef.nativeElement.querySelector('#contact-' + country);
    infoContent.classList.add('d-none');
    this.infoClosed.emit(true);
  }
}
