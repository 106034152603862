import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { HelperProvider } from '../../providers/helper.provider';

@Injectable({
  providedIn: 'root'
})
export class CareersService
{

  endpoint: string = '';

  constructor(
      private api: ApiService,
      public helper: HelperProvider
  ) {
    this.endpoint = this.helper.getEndpointCurrentHost();
  }

  getCareer(page = '01'): Observable<any>
  {
    const url = this.endpoint + '&page=careers' + page + '&type=pages';
    return this.api.get(url);
  }

  getJobDetail(): Observable<any>
  {
    const url = this.endpoint + '&page=alljobs&type=pages';
    return this.api.get(url);
  }
}
