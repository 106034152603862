import { Component, OnInit } from '@angular/core';
import { LegalService } from '../../services/legal/legal.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit
{

  public data;

  constructor(
      private legalService: LegalService,
      private meta: Meta,
      private titleService: Title,
      public helper: HelperProvider,
      public translate: TranslateService
  ) { }

  ngOnInit()
  {
      this.getLegal();
  }


  getLegal()
  {
    this.legalService.getLegal().subscribe((legal: any) => {
      this.data = (legal.length > 0) ? legal[0].acf : legal.acf;
      // SEO Tags
      if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
        this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
        this.titleService.setTitle(this.data.meta_tag_title.content);
      }

      if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
        this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
    });
  }
}
