import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { ProductService } from '../../services/product/product.service';
import { HelperProvider } from '../../providers/helper.provider';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-product-possesion',
    templateUrl: './productPossesionProtection.component.html',
    styleUrls: ['./productPossesionProtection.component.scss'],
styles: [`
   .d-none {
      display: block!important;
   }
`],
})
export class ProductPossesionProtectionComponent implements OnInit
{
    
    public data;
    public id;
    public headerImg;
    public title;
    public subTitle;
    public buttonText;
    public content;
    public country;
    public target;
    public locations;
    
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private meta: Meta,
        private titleService: Title,
        public productsService: ProductService,
        public helper: HelperProvider,
        public translate: TranslateService
    ) {}
    
    onNavigate(event: any, target: string = '_self')
    {
        this.router.navigate([event.target.value]);
    }

    ngOnInit()
    {
        this.getProduct();
    }

    getProduct()
    {
        this.productsService.getProducts('posessions-protection-plan').subscribe((products: any) => {
            this.data = (products.length > 0) ? products[0].acf : products.acf;
            // SEO Tags
            if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
                this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
                this.titleService.setTitle(this.data.meta_tag_title.content);
            }

            if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
                this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
                this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
            }
        });
    }


    openExplain($event)
    {
        $event.preventDefault();
        const link = ( $event.target.classList.contains('link') ) ? $event.target : $event.target.parentNode;
        const item = link.parentNode;
        const box = item.nextSibling;
        const wrap = item.parentNode;
        if ( box.classList.contains('open') ) {
            link.classList.remove('open');
            box.classList.remove('open');
        } else {
            for( let i = 0; i < wrap.childNodes.length; i++ ) {
                if (wrap.childNodes[i].classList.contains('open')) {
                    wrap.childNodes[i].classList.remove('open');
                }
                document.querySelectorAll('.pcp-cover-with__item__link').forEach(function(links) {
                    links.classList.remove('open')
                });
            }
            link.classList.add('open');
            box.classList.add('open');
        }
    }
}
