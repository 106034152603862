import { Component, OnInit, Input } from '@angular/core';
import { HelperProvider } from '../../providers/helper.provider';

@Component({
  selector: 'app-products-offer',
  templateUrl: './products-offer.component.html',
  styleUrls: ['./products-offer.component.scss']
})
export class ProductsOfferComponent implements OnInit
{

  @Input() data;

  constructor(
      public helper: HelperProvider
  ) { }

  ngOnInit() {}

}
