// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-bottom: 60px;
}

.mainTitle {
  font: 50px/60px "opensans", Arial, Helvetica, Sans-Serif;
  font-weight: 200;
  color: #37393e;
  margin-bottom: 60px;
}
.mainTitle .markText {
  font: 50px/60px "opensans", Arial, Helvetica, Sans-Serif;
  font-weight: 600;
  display: block;
}

.contentText {
  font: 14px/22px "opensans", Arial, Helvetica, Sans-Serif;
  font-weight: 400;
  color: #37393e;
  margin-bottom: 30px;
}
.contentText .markText {
  font: 14px/22px "opensans", Arial, Helvetica, Sans-Serif;
  font-weight: 600;
  color: #ed2d26;
}`, "",{"version":3,"sources":["webpack://./src/app/components/content05/content05.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AACA;EACI,wDAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AAEJ;AADI;EACI,wDAAA;EACA,gBAAA;EACA,cAAA;AAGR;;AAAA;EACI,wDAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AAGJ;AAFI;EACI,wDAAA;EACA,gBAAA;EACA,cAAA;AAIR","sourcesContent":[".container{\r\n    margin-bottom: 60px;\r\n}\r\n.mainTitle{\r\n    font: 50px/60px \"opensans\", Arial, Helvetica, Sans-Serif;\r\n    font-weight: 200;\r\n    color: #37393e;\r\n    margin-bottom: 60px;\r\n    .markText{\r\n        font: 50px/60px \"opensans\", Arial, Helvetica, Sans-Serif;\r\n        font-weight: 600;\r\n        display: block;\r\n    }\r\n}\r\n.contentText{\r\n    font: 14px/22px \"opensans\", Arial, Helvetica, Sans-Serif;\r\n    font-weight: 400;\r\n    color: #37393e;\r\n    margin-bottom: 30px;\r\n    .markText{\r\n        font: 14px/22px \"opensans\", Arial, Helvetica, Sans-Serif;\r\n        font-weight: 600;\r\n        color: #ed2d26;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
