import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../services/report/report.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit
{

  public data;
  public reports;

  constructor(
      private reportService: ReportService,
      private meta: Meta,
      private titleService: Title,
      public helper: HelperProvider,
      public translate: TranslateService
  ) { }

  ngOnInit()
  {
    this.getReports();
  }


  getReports()
  {
    this.reportService.getReports().subscribe((reports: any) => {
      this.data = (reports.length > 0) ? reports[0].acf : reports.acf;
      // SEO Tags
      if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
        this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
        this.titleService.setTitle(this.data.meta_tag_title.content);
      }

      if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
        this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
      const obj = this.data.content;
      this.reports = Object.keys(obj).map(e => obj[e]);
    });
  }
}
