import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content03',
  templateUrl: './content03.component.html',
  styleUrls: ['./content03.component.scss']
})
export class Content03Component implements OnInit
{
  @Input() data;

  constructor() { }

  ngOnInit() {}
}
