import { Component, OnInit } from '@angular/core';
import { CareersService } from '../../services/careers/careers.service';
import { HelperProvider } from '../../providers/helper.provider';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-careers01',
  templateUrl: './careers01.component.html',
  styleUrls: ['./careers01.component.scss']
})
export class Careers01Component implements OnInit
{

  public data;

  constructor(
      private careersService: CareersService,
      public helper: HelperProvider,
      public translate: TranslateService
  ) { }

  ngOnInit()
  {
    this.getCarrer();
  }

  getCarrer()
  {
    this.careersService.getCareer('01').subscribe((careers: any) => {
      this.data = (careers.length > 0) ? careers[0].acf : careers.acf;
    });
  }

}
