import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../services/home/home.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit
{

  public data;

  constructor(
      private homeService: HomeService,
      private meta: Meta,
      private titleService: Title,
      public helper: HelperProvider,
      public translate: TranslateService
  ) {}

  ngOnInit()
  {
    this.getHome();
  }

  scrollTo(id: string)
  {
    const el = document.getElementById(id);
    let actualScroll = window.pageYOffset;
    // Scroll to the middle of the hero height (100vh)
    const scrollInterval = setInterval(() => {
      if( actualScroll >= window.innerHeight / 2 ) {
        clearInterval(scrollInterval);
      } else {
        actualScroll += 10;
        window.scrollTo(0, actualScroll);
      }
    }, 10);
  }

  getHome()
  {
    this.homeService.getHome().subscribe((home: any) => {
      this.data = (home.length > 0) ? home[0].acf : home.acf;
      // SEO Tags
      if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
        this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
        this.titleService.setTitle(this.data.meta_tag_title.content);
      }

      if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
        this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
    });
  }

  setWhatWeDoClass(index: number)
  {
    const title = this.data.content.products_box.product_list['product_' + (index + 1)].title;
    const subtitle = this.data.content.products_box.product_list['product_' + (index + 1)].subtitle;

    return (title || subtitle) ? 'whatwedo__article' : 'whatwedo__article-nobefore';
  }
}
