import { Component, OnInit } from '@angular/core';
import { LegalService } from '../../services/legal/legal.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit
{

  public data;
  public list3;
  public list41;
  public list42;
  public list10;
  public list15;

  constructor(
      private legalService: LegalService,
      private meta: Meta,
      private titleService: Title,
      public helper: HelperProvider,
      public translate: TranslateService
  ) { }

  ngOnInit()
  {
      this.getPrivacy();
  }

  getPrivacy()
  {
    this.legalService.getPrivacy().subscribe((privacy: any) => {
      this.data = (privacy.length > 0) ? privacy[0].acf : privacy.acf;

      // SEO Tags
      if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
        this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
        this.titleService.setTitle(this.data.meta_tag_title.content);
      }

      if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
        this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }

      const obj = this.data.content.text_3.list;
      this.list3 = Object.keys(obj).map(e => obj[e]);

      const obj2 = this.data.content.text_4.list_1;
      this.list41 = Object.keys(obj2).map(e => obj2[e]);

      const obj3 = this.data.content.text_4.list_2;
      this.list42 = Object.keys(obj3).map(e => obj3[e]);

      const obj4 = this.data.content.text_10.list;
      this.list10 = Object.keys(obj4).map(e => obj4[e]);

      const obj5 = this.data.content.text_15.list;
      this.list15 = Object.keys(obj5).map(e => obj5[e]);
    });
  }

}
