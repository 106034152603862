import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HomeService } from '../../services/home/home.service';
import { HelperProvider } from '../../providers/helper.provider';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit
{

  public data;
  public logo;
  public menuFooter;
  public legal;

  constructor(
      private homeService: HomeService,
      public helper: HelperProvider,
      public ref: ChangeDetectorRef
  ) { }

  ngOnInit()
  {
      this.getFooter();
      this.getLogo();
      this.getLegal();
  }

  getFooter()
  {
    this.homeService.getMenuFooter().subscribe((menu: any) => {
      if (menu.hasOwnProperty('items')) {
        this.menuFooter = menu.items;
        this.ref.detectChanges();
      }
    });
  }

  getLogo()
  {
    this.homeService.getLogo().subscribe((logo: any) => {
      this.logo = (logo.length > 0) ? logo[0].acf : logo.acf;
    });
  }

  getLegal()
  {
    this.homeService.getLegal().subscribe((legal: any) => {
      this.legal = (legal.length > 0) ? legal[0].acf : legal.acf;
    });
  }
}
