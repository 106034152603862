import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HelperProvider } from '../../providers/helper.provider';

@Component({
  selector: 'app-article-component',
  templateUrl: './article-component.component.html',
  styleUrls: ['./article-component.component.scss']
})
export class ArticleComponentComponent implements OnInit, OnChanges
{

  @Input() data;
  @Input() slugActual;
  current;
  newsList;
  prevId: any = 2;
  prevNew: any = false;
  nextId: any = 5;
  nextNew: any = false;

  constructor(
      public helper: HelperProvider
  ) { }

  ngOnInit()
  {
    this.calcPrevNext();
  }

  ngOnChanges(changes: SimpleChanges)
  {
    if (changes.data) {
      this.data = changes.data.currentValue;
      this.calcPrevNext();
    }
  }

  calcPrevNext()
  {
    let currentIndex = null;

    this.current = this.data.find(newInfo => newInfo.SLUG === this.slugActual);
    this.current = this.current.ACF;
    this.prevNew = false;
    this.nextNew = false;
    this.newsList = this.data;

    this.newsList.map((item, index) => {
      if (item.SLUG === this.slugActual) {
        currentIndex = index;
      }
      return item;
    });

    const idActual = currentIndex;
    if (this.current.has_next === true ) {
      this.nextId = idActual + 1;

      this.data.map((item, index) => {
        if (this.nextId === index) {
          this.nextNew = item;
        }
        return item;
      });
    }

    if ( idActual >= 0 ) {
      this.prevId = idActual - 1;
      this.data.map((item, index) => {
        if (this.prevId === index) {
          this.prevNew = item;
        }

        return item;
      });
    }

    this.newsList = this.data.filter((item, index) => item.SLUG !== this.slugActual);
    this.newsList = this.newsList.filter((item, index) => index < 2);
  }

}
