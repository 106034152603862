import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content01',
  templateUrl: './content01.component.html',
  styleUrls: ['./content01.component.scss']
})
export class Content01Component implements OnInit
{

  @Input() data;

  constructor() { }

  ngOnInit() {}

}
