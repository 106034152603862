import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { HelperProvider } from '../../providers/helper.provider';

@Injectable({
  providedIn: 'root'
})
export class ProductService
{

  endpoint: string = '';

  simpleProductCategory: string = '&category_slug=simple-product';

  constructor(
      private api: ApiService,
      public helper: HelperProvider
  ) {
    this.endpoint = this.helper.getEndpointCurrentHost();
  }


  getCategory(slug = ''): Observable<any>
  {
    const url = this.endpoint + '&page=' + slug + '-category&type=products';
    return this.api.get(url);
  }

  getProducts(slug = ''): Observable<any>
  {
    const url = this.endpoint + '&page=' + slug + '&type=products';
    return this.api.get(url);
  }

  getSimpleProduct(slug = '')
  {
    const url = this.endpoint + '&page=' + slug + '&type=products' + this.simpleProductCategory;
    return this.api.get(url);
  }

  getProductsPage(): Observable<any>
  {
    const url = this.endpoint + '&page=products&type=pages';
    return this.api.get(url);
  }

}
