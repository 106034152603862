import { Component, OnInit } from '@angular/core';
import { LegalService } from '../../services/legal/legal.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-privacy-policies',
  templateUrl: './privacy-policies.component.html',
  styleUrls: ['./privacy-policies.component.scss']
})
export class PrivacyPoliciesComponent implements OnInit
{

    public data;
    public files;

    constructor(
        private legalService: LegalService,
        private meta: Meta,
        private titleService: Title,
        public helper: HelperProvider,
        public translate: TranslateService
    ) { }

    ngOnInit()
    {
        this.getPrivacyPolicies();
    }

    getPrivacyPolicies()
    {
        this.legalService.getPrivacyPolicies().subscribe((privacy: any) => {
            this.data = (privacy.length > 0) ? privacy[0].acf : privacy.acf;

            // SEO Tags
            if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
                this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
                this.titleService.setTitle(this.data.meta_tag_title.content);
            }

            if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
                this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
                this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
            }

            const obj = this.data.content.files;
            this.files = Object.keys(obj).map(e => obj[e]);
        });
    }
}
