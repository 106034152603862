import { Component, OnInit } from '@angular/core';
import { CareersService } from '../../services/careers/careers.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobDetailComponent } from '../../components/job-detail/job-detail.component';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-careers03',
  templateUrl: './careers03.component.html',
  styleUrls: ['./careers03.component.scss']
})
export class Careers03Component implements OnInit
{

  public data;
  public jobs;
  public alljobs;
  public hideShowMoreLink = false;

  constructor(
      private careersService: CareersService,
      private modalService: NgbModal,
      private meta: Meta,
      private titleService: Title,
      public helper: HelperProvider,
      public translate: TranslateService
  ) { }

  ngOnInit()
  {
    this.getCareer();
  }


  getCareer()
  {
    this.careersService.getCareer('03').subscribe((careers: any) => {
      this.data = (careers.length > 0) ? careers[0].acf : careers.acf;
      this.alljobs = JSON.parse(this.data.content.jobs_list);
      this.jobs = this.alljobs.slice(0, 6);

      if (this.alljobs.length <= 6) {
        this.hideShowMoreLink = true;
      }
    });
  }

  openJobDetails(uri)
  {
    this.careersService.getJobDetail().subscribe((job: any) => {

      const alljobs = (job.length > 0) ? JSON.parse(job[0].acf.all_jobs) : JSON.parse(job.acf.all_jobs);

      const jobInfo = alljobs.find(jobObj  => {
        return jobObj.SLUG === uri;
      });

      const modalRef = this.modalService.open(JobDetailComponent);
      modalRef.componentInstance.data = jobInfo.ACF;

      const obj = jobInfo.ACF.requirements_list;
      modalRef.componentInstance.list = Object.keys(obj).map(e => obj[e]);
    });
  }

  showAll()
  {
    this.jobs = this.alljobs;
    this.hideShowMoreLink = true;
  }
}
